import React from "react"
import PropTypes from "prop-types"
import { map } from "lodash";

import Post from "../post/post"

import "./list-posts.scss"


const ListPosts = ({ posts }) => {


  if (!posts ) return <></>

  return <div className="card-columns">
    {
     map(posts, (post) => {
      return <Post post={post} key={post.id}></Post>
     })
    }
  </div>
}

ListPosts.propTypes = {
  posts: PropTypes.any.isRequired,
}

export default ListPosts
