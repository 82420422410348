import PropTypes from "prop-types"
import React from "react"
import { Card } from 'react-bootstrap'
import moment from 'moment'
import { Link } from 'gatsby' 

import "./post.scss"

const Post = ({ post }) => {

    // Image
    let image = <></>
    if(post.imagen){
        const imageUrl = post.imagen.publicURL
        image = <div className="thumb-post" style={{backgroundImage: "url('"+imageUrl+"')"}} ></div>
    }

    // Content
    let content = <></>
    switch (post.category) {
        case 'video':
            const videoUrl = post.description.replace(/<[^>]*>?/gm, '') + '?enablejsapi=1';
            content = <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" type="text/html"
                title={post.title}
                src={videoUrl}></iframe>
            </div>

            return <Card >{content}</Card>
        case 'tweet':
        case 'instagram':
            content = post.description;
            return <Card ><div
            dangerouslySetInnerHTML={{
              __html: content
            }}></div></Card>
        default:
            const url = `/blog/${post.slug}`
            const cleanDescription = post.description.replace(/<[^>]*>?/gm, '');
            const description = cleanDescription.length > 150 ? cleanDescription.substring(0, 150) + '...' : cleanDescription;
            content = <Link to={ url }><div className="card-body">
                <div className="card-title"> {post.title} </div>
                <p className="card-text">{description}</p>
                <p className="card-text"><small className="text-muted">Published { moment(post.createdAt).format('LL') }</small></p>                
            </div></Link>
            break;
    }
 
    return <Card >
            { image }
            <Card.Body>
            { content }
            </Card.Body>
        </Card>
}

Post.propTypes = {
  post: PropTypes.any.isRequired,
}

export default Post
