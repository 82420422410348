import React from "react"
import { graphql, Link } from "gatsby"
import { Row, Container, Col } from "react-bootstrap"

import Layout from "../components/layout"
import ListPosts from "../components/list-posts/list-posts"
import SEO from "../components/seo"

export default function BlogPage(props) {
  //const posts = props.data.allStrapiPost.nodes
  const posts = []
  let prevPage = <></>
  if (props.pageContext.previousPagePath) {
    prevPage = (
      <Link
        variant="primary"
        size="lg"
        style={{ float: "right" }}
        className="btn-rounded btn btn-primary btn-lg"
        to={props.pageContext.previousPagePath}
      >
        Anterior
      </Link>
    )
  }
  let nextPage = <></>
  if (props.pageContext.nextPagePath) {
    nextPage = (
      <Link
        variant="primary"
        size="lg"
        className="btn-rounded btn btn-primary btn-lg"
        to={props.pageContext.nextPagePath}
      >
        Siguiente
      </Link>
    )
  }

  return (
    <Layout>
      <SEO title="Page blog" />
      <Container className="mt-4">
        <Row>
          <Col className="mt-4">
            <ListPosts posts={posts}></ListPosts>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="mt-4">{prevPage}</Col>
          <Col className="mt-4 justify-content-end">{nextPage}</Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = null

// graphql`query($skip: Int!, $limit: Int!) {
//     allStrapiPost(
//         sort: {fields: id, order: DESC}
//         skip: $skip
//         limit: $limit
//     ) {
//       nodes {
//         id
//         title
//         createdAt
//         slug
//         category
//         description
//         imagen {
//           publicURL
//         }
//       }
//     }
//   }`
